<template>
  <view-item-setting>
    <tabs-maintain
      v-model="tabs.status"
      :ajax="{
        action: 'GET /enocloud/common/lookup/:lookupType',
        params: (params) => (params.paths = ['FDBKSTAT']),
        convert: (data) => data.filter((item) => item.code !== 'P')
      }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>
    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          :height="height"
          code="SPFDBKFD"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
          <template #OPERATION="{ row }: { row: EnosprayCommonDefinitions['CloudFeedbackDto'] }">
            <button-delete :method="table.operation.delete.click" :params="row" v-if="row.status?.code === 'P'">删除</button-delete>
          </template>
          <template #SERIAL_NO="{ row }: { row: EnosprayCommonDefinitions['CloudFeedbackDto'] }">
            <en-button type="primary" link @click="table.operation.serialNo.click(row)">{{ row.serialNo }}</en-button>
          </template>
          <template #STATUS="{ row }: { row: EnosprayCommonDefinitions['CloudFeedbackDto'] }">
            <en-tag
              :type="
                row.status?.message === '未提交'
                  ? 'info'
                  : row.status?.message === '已解决'
                  ? 'success'
                  : row.status?.message === '未处理'
                  ? 'warning'
                  : row.status?.message === '未解决'
                  ? 'danger'
                  : ''
              "
              >{{ row.status?.message }}</en-tag
            >
          </template>
        </table-dynamic>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑' : '添加' + '车品牌类型'" size="40%">
    <en-form
      :model="detail.form.data"
      :rules="detail.form.rules"
      :loading="detail.form.loading"
      :ref="setRef('detailForm')"
      class="grid grid-cols-2 gap-x-6"
    >
      <en-form-item label="反馈人" prop="feedbackBy">
        <select-maintain
          v-model="detail.form.data.feedbackBy"
          :ajax="{
            action: 'GET /enocloud/common/user',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
          :disabled="['UH', 'R', 'US', 'S'].includes(detail.form.data.status.code)"
        >
        </select-maintain>
      </en-form-item>
      <en-form-item label="问题模块" prop="modules">
        <select-maintain
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['FDBKMD'])
          }"
          :props="{ label: 'message', value: 'code' }"
          :disabled="['UH', 'R', 'US', 'S'].includes(detail.form.data.status.code)"
          v-model="detail.form.data.modules"
          value-key="id"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="问题标题" prop="title">
        <en-input v-model="detail.form.data.title" :disabled="['UH', 'R', 'US', 'S'].includes(detail.form.data.status.code)"></en-input>
      </en-form-item>

      <en-form-item label="问题编号" prop="serialNo">
        <en-input v-model="detail.form.data.serialNo"></en-input>
      </en-form-item>
      <en-form-item label="反馈门店" prop="name">
        <en-input v-model="detail.form.data.branch.name" :disabled="['UH', 'R', 'US', 'S'].includes(detail.form.data.status.code)"></en-input>
      </en-form-item>
      <en-form-item label="问题描述" prop="content">
        <en-input
          v-model="detail.form.data.content"
          :disabled="['UH', 'R', 'US', 'S'].includes(detail.form.data.status.code)"
          type="textarea"
        ></en-input>
      </en-form-item>
      <en-form-item label="问题回复" prop="reply">
        <en-input v-model="detail.form.data.reply" type="textarea"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        children: {}
      },
      tabs: {
        status: ''
      },

      form: {
        is: 'form',
        data: {
          serialNo: '',
          endReplyDate: '',
          startReplyDate: '',
          endFeedbackDate: '',
          startFeedbackDate: '',
          feedbackBy: '',
          handler: '',
          modules: []
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enospray/common/feedback',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { ...this.form.data, ...this.tabs }
            }
          },
          delete: {
            action: 'DELETE /enocloud/common/feedback/:feedbackId/delete',
            loading: true
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudCommonDefinitions['CloudFeedbackDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            },
            serialNo: {
              click(row: EnocloudCommonDefinitions['CloudFeedbackDto']) {
                this.detail.form.init()
                this.detail.form.data.id = row.id
                this.detail.form.get()
                this.detail.visible = true
              }
            }
          }
        },
        config: {
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          MODULES: {
            header: {
              filter: {
                type: 'select',
                field: 'module',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['FDBKMD']
                    }
                  },
                  remote: true,
                  multiple: true,
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          FEEDBACK_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['startFeedbackDate', 'endFeedbackDate'],
                props: { type: 'daterange' }
              }
            }
          },
          HANDLER: { header: { filter: { type: 'text', field: 'handler' } } },
          REPLY_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['startReplyDate', 'endReplyDate'],
                props: { type: 'daterange' }
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              serialNo: '',
              branch: {},
              feedbackBy: { name: '' },
              modules: [],
              title: '',
              content: '',
              reply: '',
              status: {
                code: ''
              },
              solved: { code: '' },
              name: ''
            },
            ajax: {
              get: {
                action: 'GET /enospray/common/feedback/:feedbackId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enospray/common/feedback',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              feedbackBy: [{ required: true, message: '请输入反馈人' }],
              content: [{ required: true, message: '请输入问题描述' }]
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form.submit()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
